import { graphql } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import ProductGrid from "../components/ProductGrid";

export default function Home(props) {

	const productCollection = props.data.allProducts.nodes;

	return (
		<Layout hasSlider={true} hasSidebar={false}>
			<div className="h-fit py-6 pl-12">
				<p className="font-semibold text-xl">Productos Destacados</p>
				<hr className="w-full mt-2 mb-8 border-gray-200 border-x-2"></hr>
				<ProductGrid products={ productCollection } />
			</div>
		</Layout>
	);
}

export const pageQuery = graphql`
    query IndexProducts {
        allProducts(filter: {is_featured: {eq: 1}}) {
            nodes {
                sku
                name
                price
                slug
            }
        }
    }
`;
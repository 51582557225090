import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import useLoggedIn from "../hooks/useLoggedIn";
import ProductCard from "./ProductCard";

const ProductGrid = ({products}) => {

	const { auth } = useAuth();
	const isLoggedIn = useLoggedIn();
	const [isLoggedInState, setIsLoggedInState] = useState(auth?.user);
	const [maxItemsToShow, setMaxItemsToShow] = useState(30);

	useEffect(() => {
		const checkIfLoggedIn = async () => await isLoggedIn();
		checkIfLoggedIn();
	}, []);

	useEffect(() => setIsLoggedInState(auth?.user), [auth]);

	const handleShowMore = () => {
		setMaxItemsToShow((prevMaxItemsToShow) => prevMaxItemsToShow + 30);
	};

	return (
		<div className="text-center">
			<div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 justify-evenly">
				{
					products.slice(0, maxItemsToShow).map((product, index) => (
						<ProductCard product={ product } key={ index } showPrice={isLoggedInState}/>
					))
				}
			</div>
			{
				products.length > maxItemsToShow
                &&
                <button onClick={handleShowMore} className="px-6 py-2 mt-8 inline-block rounded-md bg-blue-700 text-white">Mostrar más</button>
			}
		</div>
	);
};

export default ProductGrid;
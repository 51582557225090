import { Link } from "gatsby";
import React from "react";
import defaultImage from "../assets/img/logo.png";


const ProductCard = ({ product, showPrice }) => {

	function addDefaultSrc(e) {
		e.target.src = defaultImage;
	}


	return (
		<Link to={"/products/" + product.slug }>
			<article className="h-72 border px-6 py-4 hover:shadow hover:transition-all duration-700">
				<div className="flex justify-between items-center flex-col h-full">
					<img className="h-44 w-44 object-contain" onError={ addDefaultSrc } src={ process.env.GATSBY_WORDPRESS_BASE_URL + "/wp-content/uploads/product-images/" + product.sku + ".jpg" } alt={ product.name }></img>
					<div className="text-center mt-2 w-full">
						<small>{ product.sku }</small>
						<h1 className="text-sm overflow-hidden text-ellipsis whitespace-nowrap hover:whitespace-normal">{ product.name }</h1>
						{
							showPrice &&
								<h1 className="text-md font-semibold overflow-hidden text-ellipsis whitespace-nowrap hover:whitespace-normal">
									{
										product.price === 0 ? "AGOTADO" : "$ " + product.price
									}
								</h1>
						}
					</div>
				</div>
			</article>
		</Link>
	);
};

export default ProductCard;